<script>
import {find} from "@/domain/marketplace/services/produtos"
import SlLoading from "components/layout/components/Loading"
import * as FormasPagamento from "@/domain/gerencia/produtos/helpers/formaPagamento"
import * as CriteriosCobranca from "@/domain/gerencia/produtos/helpers/criterioCobranca"

export default {
  name: "Produto",
  components: {SlLoading},
  data() {
    return {
      loading: true,
      produto: null
    }
  },
  computed: {
    formasPagamento () {
      return FormasPagamento
    },
    criteriosCobranca () {
      return CriteriosCobranca
    }
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    load(id) {
      this.loading = true
      find(id, true)
          .then(response => {
            this.produto = response.data
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    contratar () {
      this.$router.push({name: 'marketplace.produto.contratar', params: {id: this.produto.id}})
    }
  }
}
</script>

<template>
  <div class="produto-container">
    <div v-if="!loading" class="produto">
      <div @click="$router.push({name: 'marketplace.dashboard'})" class="close-btn"><i class="fa-solid fa-times" /> </div>
      <div class="produto-header">
        <div class="pheader-desc">
          <h2>{{produto.nome}}</h2>
          <p>{{produto.descricaoSimples}}</p>
        </div>
      </div>
      <div class="produto-body">
        <div class="produto-menu">
          <table>
            <tr v-if="produto.valor">
              <td>Custo</td>
              <td v-if="produto.criterioUsos && produto.criterioUsos.length">De acordo uso</td>
              <td v-else>R$ {{produto.valor|moeda2}}</td>
            </tr>
            <tr>
              <td>Ativação</td>
              <td v-if="produto.taxaInstalacao">R$ {{produto.taxaInstalacao|moeda}}</td>
              <td v-else>Sem custo</td>
            </tr>
            <tr>
              <td>Cobrança</td>
              <!--<td>{{produto.unidadeMedidaCobrancaDescricao}}</td>-->
              <td v-if="!produto.valor && !produto.taxaInstalacao">
                Não tem
              </td>
              <td v-else>
                {{ criteriosCobranca.CriteriosCobranca[produto.criterioCobranca]?.title }}
              </td>
            </tr>
            <tr v-if="produto.criterioUsos && produto.criterioUsos.length">
              <td colspan="100%" style="text-align: left; padding-top: 12px; padding-bottom: 4px">
                Definições de Custo {{produto.unidadeMedidaCobrancaDescricao}}
              </td>
            </tr>
            <tr v-for="criterio in produto.criterioUsos">
              <td>
                <div v-if="criterio.limiteSuperior == '0' || criterio.limiteSuperior">
                De {{criterio.limiteInferior|number}} até {{criterio.limiteSuperior|number}}
                </div>
                <div v-else>A partir de {{criterio.limiteInferior|number}}</div>
              </td>
              <td>R$ {{criterio.valorUnidade|moeda2}}</td>
            </tr>
          </table>
          <div class="produto-actions" v-if="!produto.contratosAtivos || !produto.quantidadeMaxima || produto.contratosAtivos < produto.quantidadeMaxima">
            <u-btn @click="contratar" :label="!produto.valor && !produto.taxaInstalacao ? 'Ativar sem custo' : 'Contratar e ativar'" color="green" no-caps class="b-radius-3px" />
          </div>
          <div class="produto-actions" v-else>
            <u-btn disable label="Este produto está ativo" color="green" no-caps class="b-radius-3px" />
            <div class="m-t">
              Caso queira cancelar este serviço acesse o menu Faturamento e cancele o contrato ativo.
            </div>
          </div>
        </div>
        <div class="produto-content">
          <div v-html="produto.descricaoCompleta"></div>
        </div>
      </div>
    </div>
    <div v-else class="produto loading">
      <sl-loading />
      <div class="m-t">Carregando informações sobre o produto..</div>
    </div>
  </div>
</template>
